import React, { useState, useContext } from "react";
import { GlobalContext } from '../state/GlobalState';
import AuthService from "../services/AuthService";
import Common from '../common/Common';

export default function Login() {
    const authSvc = new AuthService();
    const { setIsSigned } = useContext(GlobalContext)
    const [birthYear, setBirthYear] = useState("");
    const [employeeNumber, setEmployeeNumber] = useState("");
    const [smsCode, setSmsCode] = useState("");
    const [showSmsCode, setShowSmsCode] = useState(false);
    const [phone, setPhone] = useState(false);

    const login = async () => {
        if (showSmsCode) { //sms code check
            //#region validation

            let errorMessage = "";

            if (birthYear === "") { errorMessage += "הכנס שנת לידה<br />"; }
            else if (birthYear.length !== 4 || isNaN(birthYear) || Number(birthYear < 1920) || Number(birthYear > 2010)) { errorMessage += "שנת לידה לא חוקית<br />"; }

            if (employeeNumber === "") { errorMessage += "הכנס מס׳ עובד<br />"; }
            else if (isNaN(employeeNumber)) { errorMessage += "מס׳ עובד לא חוקי<br />"; }

            if (smsCode === "") { errorMessage += "הכנס קוד SMS<br />"; }

            if (errorMessage !== "") {
                Common.displayError(errorMessage, "");
                return;
            }

            //#endregion

            let response = await authSvc.codeCheck(birthYear, employeeNumber, smsCode);
            if (!response.succeeded || response.data.token === "") {
                Common.displayError(response.message, "");
                return;
            }

            localStorage.setItem("token", response.data.token)
            setIsSigned(true);
        }
        else { //login
            //#region validation

            let errorMessage = "";

            if (birthYear === "") { errorMessage += "הכנס שנת לידה<br />"; }
            else if (birthYear.length !== 4 || isNaN(birthYear) || Number(birthYear < 1920) || Number(birthYear > 2010)) { errorMessage += "שנת לידה לא חוקית<br />"; }

            if (employeeNumber === "") { errorMessage += "הכנס מס׳ עובד<br />"; }
            else if (isNaN(employeeNumber)) { errorMessage += "מס׳ עובד לא חוקי<br />"; }

            if (errorMessage !== "") {
                Common.displayError(errorMessage, "");
                return;
            }

            //#endregion

            let response = await authSvc.signin(birthYear, employeeNumber);
            if (!response.succeeded) {
                Common.displayError(response.message, "");
                return;
            }

            setPhone(response.data.phone);

            setShowSmsCode(true);
        }
    }

    return (
        <div className="login">
            {/*<img className="login-img-left" src="images/login-img-left.png" alt="" />*/}<img className="login-img-right" src="images/train-illustration.png" alt="" />
            <form id="form1" runat="server">
                <div className="container">
                    <img className="logo" src="images/logo.png" alt="לוגו" />
                    <div className="tb-container">
                        שנת לידה*<br /><input className="tb" type="text" value={birthYear} onChange={e => setBirthYear(e.target.value)} maxLength="4" />
                    </div>
                    <div className="tb-container last">
                        מס׳ עובד*<br /><input className="tb" type="text" value={employeeNumber} onChange={e => setEmployeeNumber(e.target.value)} maxLength="10" />
                    </div>
                    {showSmsCode &&
                        <div className="tb-container code">
                        הזן את הקוד שקיבלת בSMS לטלפון שמספרו מסתיים ב{phone}*<br />
                            <input className="tb" type="text" value={smsCode} onChange={e => setSmsCode(e.target.value)} />
                        </div>
                    }
                    <input className="login-btn" type="button" value="כניסה לאתר" onClick={login} />
                </div>
            </form>
            <div className="top-footer"></div>
            <div className="bottom-footer"></div>
        </div>
    );
}