import React, { useEffect, useState } from 'react';
import Categories from './Categories';
import Courses from './Courses';
import Contact from './Contact';
import { useParams, useHistory, Link } from "react-router-dom";
import jQuery from 'jquery';
import './slimmenu.min.css';
import './jquery.slimmenu.min.js';

export function Layout() {
    useEffect(() => {
        (async () => {
            jQuery('ul.slimmenu').slimmenu({
                resizeWidth: '800',
                collapserTitle: '',
                easingEffect: 'easeInOutQuint',
                animSpeed: 'medium',
                indentChildren: true,
                childrenIndenter: '&raquo;'
            });
        })();
    }, []);

    const params = useParams();
    let typeParam = params.type ?? "הכל";
    let catParam = params.cat ?? "";

    let catTypeParam = typeParam;
    if (catTypeParam === "צור_קשר")
        catTypeParam = "הכל";
    
    const query = new URLSearchParams(window.location.search);
    const searchParam = query.get('search')

    const history = useHistory();
    const [search, setSearch] = useState(searchParam ?? "");

    let searchTbKeyDownHandler = (e) => {
        if (e.key === 'Enter') {
            history.push(search === "" ? "/" : `/?search=${search}`)
        }
    }

    return (
        <div className="pages">
            <header>
                <a href="https://www.ladaatbegadol.co.il/"><img className="logo_h" alt="לוגו" src="images/logo_inner.png" /></a>
                <ul className="slimmenu">
                    <li key="courses"><Link to={"/קורסים/" + catParam}>קורסים</Link></li>
                    <li key="lectures"><Link to={"/הרצאות/" + catParam}>הרצאות</Link></li>
                    <li key="workshops"><Link to={"/סדנאות/" + catParam}>סדנאות</Link></li>
                    <li key="conferences"><Link to={"/כנסים/" + catParam}>כנסים</Link></li>
                    <li key="virtual_lectures"><Link to={"/הרצאות_וירטואליות/" + catParam}>הרצאות וירטואליות</Link></li>
                    <li key="tours"><Link to={"/סיורים/" + catParam}>סיורים</Link></li>
                    {/*<li key="about"><Link to="#">אודות</Link></li>*/}
                    <li key="contact"><Link to="/צור_קשר" className="Selected">יצירת קשר</Link></li>
                </ul>
                <section className="search">
                    <input className="search-tb" type="text" value={search} onChange={e => setSearch(e.target.value)} onKeyDown={e => searchTbKeyDownHandler(e)} />
                    <input className="search-btn" type="button" onClick={() => history.push(search === "" ? "/" : `/?search=${search}`)} />
                </section>
            </header>
            <div className="container">
                {/*<section className="bread_crumbs">*/}
                {/*    <span>קורסים מומלצים ל:</span>*/}
                {/*    <ul>*/}
                {/*        <Categories type={catTypeParam} />*/}
                {/*    </ul>*/}
                {/*</section>*/}
                {typeParam == "צור_קשר" ? <Contact /> : <Courses type={typeParam} cat={catParam} search={searchParam} />}
            </div>
            {/*<div className="top-footer"></div>*/}
            {/*<footer>*/}
            {/*    <div className="container">*/}
            {/*        <section className="site-map">*/}
            {/*            <ul>*/}
            {/*                <li><a href="#">מפת האתר</a></li>*/}
            {/*                <li><a href="#">יצירת קשר</a></li>*/}
            {/*                <li><a href="#">תקנון</a></li>*/}
            {/*            </ul>*/}
            {/*        </section>*/}
            {/*        <section className="courses">*/}
            {/*            <h3>רשימת קורסים</h3>*/}
            {/*            <ul>*/}
            {/*                <li><a href="#">אנגלית</a></li>*/}
            {/*                <li><a href="#">אקסל</a></li>*/}
            {/*                <li><a href="#">ניהול פקוייקטים בסיסי</a></li>*/}
            {/*                <li><a href="#">סדנאות פיתוח חוסן נפשי</a></li>*/}
            {/*                <li><a href="#">מדעי האושר</a></li>*/}
            {/*                <li><a href="#">בי״ס ללמידה דיגיטלית</a></li>*/}
            {/*                <li><a href="#">מיומנויות פרזנטציה</a></li>*/}
            {/*                <li><a href="#">אנגלית</a></li>*/}
            {/*                <li><a href="#">אקסל</a></li>*/}
            {/*                <li><a href="#">ניהול פקוייקטים בסיסי</a></li>*/}
            {/*                <li><a href="#">סדנאות פיתוח חוסן נפשי</a></li>*/}
            {/*                <li><a href="#">מדעי האושר</a></li>*/}
            {/*                <li><a href="#">בי״ס ללמידה דיגיטלית</a></li>*/}
            {/*                <li><a href="#">מיומנויות פרזנטציה</a></li>*/}
            {/*            </ul>*/}
            {/*            <ul>*/}
            {/*                <li><a href="#">אנגלית</a></li>*/}
            {/*                <li><a href="#">אקסל</a></li>*/}
            {/*                <li><a href="#">ניהול פקוייקטים בסיסי</a></li>*/}
            {/*                <li><a href="#">סדנאות פיתוח חוסן נפשי</a></li>*/}
            {/*                <li><a href="#">מדעי האושר</a></li>*/}
            {/*                <li><a href="#">בי״ס ללמידה דיגיטלית</a></li>*/}
            {/*                <li><a href="#">מיומנויות פרזנטציה</a></li>*/}
            {/*                <li><a href="#">אנגלית</a></li>*/}
            {/*                <li><a href="#">אקסל</a></li>*/}
            {/*                <li><a href="#">ניהול פקוייקטים בסיסי</a></li>*/}
            {/*                <li><a href="#">סדנאות פיתוח חוסן נפשי</a></li>*/}
            {/*                <li><a href="#">מדעי האושר</a></li>*/}
            {/*                <li><a href="#">בי״ס ללמידה דיגיטלית</a></li>*/}
            {/*                <li><a href="#">מיומנויות פרזנטציה</a></li>*/}
            {/*            </ul>*/}
            {/*            <ul>*/}
            {/*                <li><a href="#">אנגלית</a></li>*/}
            {/*                <li><a href="#">אקסל</a></li>*/}
            {/*                <li><a href="#">ניהול פקוייקטים בסיסי</a></li>*/}
            {/*                <li><a href="#">סדנאות פיתוח חוסן נפשי</a></li>*/}
            {/*                <li><a href="#">מדעי האושר</a></li>*/}
            {/*                <li><a href="#">בי״ס ללמידה דיגיטלית</a></li>*/}
            {/*                <li><a href="#">מיומנויות פרזנטציה</a></li>*/}
            {/*                <li><a href="#">אנגלית</a></li>*/}
            {/*                <li><a href="#">אקסל</a></li>*/}
            {/*                <li><a href="#">ניהול פקוייקטים בסיסי</a></li>*/}
            {/*                <li><a href="#">סדנאות פיתוח חוסן נפשי</a></li>*/}
            {/*                <li><a href="#">מדעי האושר</a></li>*/}
            {/*                <li><a href="#">בי״ס ללמידה דיגיטלית</a></li>*/}
            {/*                <li><a href="#">מיומנויות פרזנטציה</a></li>*/}
            {/*            </ul>*/}
            {/*        </section>*/}
            {/*        <section className="social">*/}
            {/*            <h3>חפשו אותנו</h3>*/}
            {/*            <ul>*/}
            {/*                <li><a href="#"><img alt="youtube" src="images/youtube_btn.svg" /></a></li>*/}
            {/*                <li><a href="#"><img alt="linkedin" src="images/linkedin_btn.svg" /></a></li>*/}
            {/*                <li><a href="#"><img alt="facebook" src="images/facebook_btn.svg" /></a></li>*/}
            {/*            </ul>*/}
            {/*        </section>*/}
            {/*    </div>*/}
            {/*</footer>*/}
        </div>
    );
}
