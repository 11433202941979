import ServiceResponse from './ServiceResponse';

export default class AuthService {
    async signin(birthYear, employeeNumber) {
        try {
            let succeeded = false;
            let status = "";
            let message = "";

            let response = await fetch("/AuthContoller/Login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ birthYear, employeeNumber }),
            });

            succeeded = response.ok;
            status = response.status;

            let data = await response.text();
            if (data.length > 0) {
                try {
                    let jsonData = JSON.parse(data);
                    data = jsonData;
                    if (data.hasOwnProperty("message"))
                        message = data.message
                }
                catch { }
            }

            return new ServiceResponse(succeeded, status, message, data);
        } catch (err) {
            console.log(err);
        }
    }

    async codeCheck(birthYear, employeeNumber, code) {
        try {
            let succeeded = false;
            let status = "";
            let message = "";

            let response = await fetch("/AuthContoller/ValidateCode", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ birthYear, employeeNumber, code }),
            });

            succeeded = response.ok;
            status = response.status;

            let data = await response.text();
            if (data.length > 0) {
                try {
                    let jsonData = JSON.parse(data);
                    data = jsonData;
                    if (data.hasOwnProperty("message"))
                        message = data.message
                }
                catch { }
            }

            return new ServiceResponse(succeeded, status, message, data);
        } catch (err) {
            console.log(err);
        }
    }
}