import React from 'react';
import withReactContent from 'sweetalert2-react-content'
import CoursesService from "../services/CoursesService";
import Swal from 'sweetalert2'

export default class Common {
    static displayError = (errorMessage, errorTitle) => {
        var errorMessageTitle = "שגיאה";
        if (errorTitle !== undefined && errorTitle !== null && errorTitle !== "")
            errorMessageTitle += " - " + errorTitle

        Swal.fire({
            title: errorMessageTitle,
            html: errorMessage,
            icon: 'error'
        })
    }

    static addToFileName = (fileName, stringToAdd) => {
        var seperatorIndex = fileName.lastIndexOf('.');
        return fileName.substring(0, seperatorIndex) + stringToAdd + fileName.substring(seperatorIndex, fileName.length);
    }

    static register = async (event, courseId, withManagerApproval, isFull, isRegistered, courseName, courseTypeName) => {
        if ((isRegistered || event.target.classList.contains("course-full")) && !withManagerApproval) {
            Common.displayError("הבקשה שלך להרשמה התקבלה.<br />האישור הסופי להרשמה לקורס יינתן על ידי צוות ההדרכה.", "");
            return false;
        }
        else if ((isRegistered) && withManagerApproval) {
            const MySwal = withReactContent(Swal)

            await MySwal.fire({
                title: <></>,
                html: <>הבקשה שלך להרשמה התקבלה. אנא העבר\י את הטופס שהורדת מלא וחתום.<br />האישור הסופי להרשמה לקורס יינתן על ידי צוות ההדרכה.<br />להורדה חוזרת של הטופס <a className="underline pointer" onClick={e => Common.downloadFile(courseId)}>לחצ\י כאן</a>.</>,
                icon: 'info'
            })

            return false;
        }
        
        if (isFull) {
            Common.displayError("הקורס מלא", "");
            return false;
        }
        
        if (withManagerApproval) {
            const coursesService = new CoursesService();
            let response = await coursesService.register(courseId);

            if (!response.succeeded) {
                Common.displayError(response.message, "");
                return;
            }

            let sender = event.target;
            sender.classList.add('course-full');

            if (response.data != null && response.data["filename"] != null && response.data["filename"] != "") {
                coursesService.downloadCourse(courseId);
            }

            Swal.fire({
                title: "שים/י לב!",
                text: "על מנת להשלים את ההרשמה יש להחתים את המנהל\\ת על הקובץ המצורף.",
                icon: 'info'
            })
        }
        else {
            Swal.fire({
                title: 'לחץ אישור כדי להירשם ל' + courseTypeName + ' ' + courseName,
                showCancelButton: true,
                confirmButtonText: 'אישור',
                cancelButtonText: `בטל`,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const coursesService = new CoursesService();
                    let response = await coursesService.register(courseId);

                    if (!response.succeeded) {
                        Common.displayError(response.message, "");
                        return;
                    }

                    let sender = event.target;
                    sender.classList.add('course-full');

                    if (response.data != null && response.data["filename"] != null && response.data["filename"] != "") {
                        coursesService.downloadCourse(courseId);
                    }

                    Swal.fire({
                        title: "הבקשה שלך להרשמה התקבלה.<br />האישור הסופי להרשמה לקורס יינתן על ידי צוות ההדרכה.",
                        icon: 'success'
                    })
                }
            })
        }
    }

    static downloadFile = (courseId) => {
        const coursesService = new CoursesService();
        coursesService.downloadCourse(courseId);
    }
}

//export function displayError(errorMessage, errorTitle) {
//    var errorMessageTitle = "שגיאה";
//    if (errorTitle != undefined && errorTitle != null && errorTitle != "")
//        errorMessageTitle += " - " + errorTitle

//    Swal.fire({
//        title: errorMessageTitle,
//        text: errorMessage,
//        icon: 'error'
//    })
//}
