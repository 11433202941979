import ServiceResponse from './ServiceResponse';

export default class CategoriesService {
    async getCategories() {
        try {
            let succeeded = false;
            let status = "";
            let message = "";

            let response = await fetch("/CategoriesContoller/GetCategories", {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
            });

            succeeded = response.ok;
            status = response.status;

            let data = await response.text();
            if (data.length > 0) {
                try {
                    let jsonData = JSON.parse(data);
                    data = jsonData;
                    if (data.hasOwnProperty("message"))
                        message = data.message
                }
                catch { }
            }
            
            return new ServiceResponse(succeeded, status, message, data);
        } catch (err) {
            console.log(err);
        }
    }
}