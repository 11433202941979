import React, { createContext, useReducer } from 'react';

const initialState = {
  isSigned: localStorage.getItem("token") != null
}

const keys = {
  setIsSigned: 'set_is_signed'
};

const reducer = (state, action) => {
  switch (action.type) {
    case keys.setIsSigned:
      return {
        ...state,
        isSigned: action.payload
      }
    default:
      return state;
  }
}

const getDispatchers = (state, dispatch) => {
  return {
    setIsSigned(payload) {
      if (!payload) {
        localStorage.removeItem("token");
      }
      dispatch({ type: keys.setIsSigned, payload });
    },
  };
}

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const dispatchers = getDispatchers(state, dispatch);

  return (
    <GlobalContext.Provider value={{ ...state, ...dispatchers }}>
      {children}
    </GlobalContext.Provider>
  )
}