import React, { useEffect, useState } from 'react';

export default function Contact(props) {
    return (
        <>
            <section className="contact">
                <h1>יצירת קשר</h1>
                <div className="txt-container">
                    במידה ונתקלתם בבעיה תוכלו ליצור קשר עם ליאת פרידברג, מנהלת ענף הדרכה.<br />
                    טלפון-  <a href="tel:089548036">08-9548036</a>, אימייל- <a href="mailto:LIATF@rail.co.il">LIATF@rail.co.il</a>
                    <br /><br />
                    במידה ואתם מהנדסים ונתקלתם בבעיה תוכלו ליצור קשר עם ענת רוטלוי, מנהלת תוכניות אקדמאיות.<br />
                    טלפון- <a href="tel:086533636">08-6533636</a>, אימייל- <a href="mailto:ANATR2@rail.co.il">ANATR2@rail.co.il</a>
                </div>
            </section>
        </>
    )
}
