import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import { unregister as unregisterServiceWorker } from './registerServiceWorker'
import { GlobalProvider } from './state/GlobalState';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <GlobalProvider>
            <App />
        </GlobalProvider>
    </BrowserRouter>,
    rootElement);

//registerServiceWorker();
unregisterServiceWorker();

