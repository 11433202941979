import React, { Fragment } from 'react';
import Course from './Course';

export default function MonthEvents(props) {
    const monthNames = ["", "ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני", "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"];

    return (
        props.months && props.months.map(month => {
            let events = props.courses.filter(obj => { return obj.courseMonth === month });

            let col1 = [];
            let col2 = [];
            for (var i = 0; i < events.length; i++) {
                if (i % 2 == 0)
                    col1.push(events[i]);
                else
                    col2.push(events[i]);
            }

            let currentMonth = isNaN(month) || Number(month) === 0 ? 0 : Number(month.substring(0, 2));
            let currentYear = Number(month) === 0 ? "" : month.substr(2, 4);
            if (currentMonth == "0") {
                if (month == "future_courses")
                    currentYear = "הכשרות עתידיות";
                else
                    currentYear = "קורסים דיגיטליים";
            }
            
            return (
                <Fragment key={month}>
                    <h1>{monthNames[currentMonth]} {currentYear}</h1>
                    <section className="events-courses">
                        {
                            col1 && col1.map(course => {
                                return <Course key={course.id} course={course} />
                            })
                        }
                    </section>
                    <section className="events-workshop">
                        {
                            col2 && col2.map(course => {
                                return <Course key={course.id} course={course} />
                            })
                        }
                        {/*<a className="generall-btn-style" href="#">הרצאות וסדנאות בחודשים הבאים</a>*/}
                    </section>
                </Fragment>
            )
        })
    );

}
