import React, { useEffect, useState } from 'react';
import Common from '../common/Common';
import CategoriesService from "../services/CategoriesService";
import { Link } from "react-router-dom";

export default function Categories(props) {
    const [categories, setCategories] = useState(null);

    useEffect(() => {
        (async () => {
            const categoriesService = new CategoriesService();
            let response = await categoriesService.getCategories();

            if (!response.succeeded) {
                Common.displayError(response.message, "");
                return;
            }

            setCategories(response.data);
        })();
    }, []);

    return categories && categories.map(category => (<li key={category.id}><Link to={"/" + props.type.replaceAll(' ', '_') + "/" + category.categoryName.replaceAll(' ', '_')}>{category.categoryName}</Link></li>));
}
