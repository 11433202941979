import React from 'react';
import Common from '../common/Common';
import jQuery from 'jquery';
import './verticalFade.js';

export default function Course(props) {
    let courseImage = "images/img_workshop_box.png";
    if (props.course.courseImage !== "") {
        //courseImage = "Uploads/Courses/" + Common.addToFileName(props.course.courseImage, "_" + props.course.courseType);
        courseImage = "Uploads/Courses/" + Common.addToFileName(props.course.courseImage, "_course");
    }

    let courseTypeName = "הרצאה";
    if (props.course.courseType === "workshop")
        courseTypeName = "סדנה";
    else if (props.course.courseType === "course")
        courseTypeName = "קורס";
    else if (props.course.courseType === "conference")
        courseTypeName = "כנס";
    else if (props.course.courseType === "virtual_lecture")
        courseTypeName = "הרצאה וירטואלית";
    else if (props.course.courseType === "tour")
        courseTypeName = "סיור";

    const viewMoreDetails = (e) => {
        let context = jQuery(e.target).closest(".events-courses-box");
        jQuery(".hiddenDetailsContainer", context).verticalFade({
            duration: 'slow', complete: function () {

            }
        });
    }

    let submitButtonClass = "course-reg-btn";
    if (props.course.isFull || props.course.isRegistered)
        submitButtonClass += " course-full";

    const monthsWithoutHour = ["digital_courses", "future_courses"];

    return (
        <div className="events-courses-box events-courses-box-full">
            <div className="img-contaner">{props.course.isFull && <span class="closed-courses">ההרשמה נסגרה</span>}<img className="events-courses-box-img" alt="" src={courseImage} /></div>
            <div className="time-place-info course-min-height">
                <h2><span className="type">{courseTypeName}</span> {props.course.courseName}  </h2>
                <div className="generalInfo">
                    <span><b>מסלולי למידה ייעודיים ל:</b> <span className="events-courses-cat-name">{props.course.courseCategoryNames}</span></span><br />
                    {props.course.teacher !== "" && <><span><b>מרצה:</b> {props.course.teacher}</span><br /></>}
                    {props.course.numberOfSessions !== "" && <span><b>מספר מפגשים:</b> {props.course.numberOfSessions}</span>}</div>
                {
                    (props.course.courseMonth !== "000000" && (props.course.courseStartDate !== "" || props.course.courseEndDate !== "" || props.course.courseHours !== "")) &&
                    <>
                        <div className="coursesInfo">
                            {props.course.courseStartDate !== "" && props.course.courseStartDate.includes("2099") == false && <span className="date">תאריך התחלה<br /><span className="big-num">{props.course.courseStartDate}</span></span>}
                            {props.course.courseEndDate !== "" && <span className="date last">תאריך סיום<br /><span className="big-num">{props.course.courseEndDate}</span></span>}
                        </div>
                        {(monthsWithoutHour.includes(props.course.courseMonth) === false || props.course.courseHours !== "") && <div className="coursesTime"><span className="hour">שעה<br />{props.course.courseHours !== "" && <span className="big-num">{props.course.courseHours}</span>}</span></div>}
                    </>
                }
                {
                    <div className="coursesInfo place">
                        <span><b>מיקום: {props.course.courseLocation}</b></span>
                    </div>
                }
                <div className="more-info-container">
                    <a className={"more-info-btn" + (!(props.course.courseDescription !== "" || props.course.syllabus !== "") ? " v_hidden" : "")} onClick={viewMoreDetails} >לפרטים נוספים</a>
                    {
                        (props.course.courseDescription !== "" || props.course.syllabus !== "") &&
                        <>
                            
                            <div className="hiddenDetailsContainer d_none">
                                <div className="smallFont">
                                    {/* {props.course.syllabus !== "" && <><div className="syllabusTitle"><b>סילבוס:</b></div></>}*/}
                                    {props.course.syllabus !== "" && <><span dangerouslySetInnerHTML={{ __html: props.course.syllabus }} /><br /><br /></>}
                                    <span dangerouslySetInnerHTML={{ __html: props.course.courseDescription }} />
                                </div>
                            </div>
                        </>
                    }
                    <div className="width300px bottons-container">
                        {/*<a className="more-info-btn" href="#">לסגירת הפרטים הנוספים</a>*/}
                        <input className={submitButtonClass} type="button" value="להרשמה" onClick={e => Common.register(e, props.course.id, props.course.withManagerApproval, props.course.isFull, props.course.isRegistered, props.course.courseName, courseTypeName)} />
                        <div className="clearB"></div>
                    </div>
                </div>
            </div>
            <div className="clearB"></div>
        </div>
    );
}
