import ServiceResponse from './ServiceResponse';

export default class CoursesService {
    async getCourses(type, cat, search) {
        try {
            let succeeded = false;
            let status = "";
            let message = "";

            let queryString = "";
            if (type !== null && type !== "" && type !== "all")
                queryString += `&CourseType=${type}`;
            if (cat !== null && cat !== "" && cat !== "all")
                queryString += `&CourseCategory=${cat}`;
            if (search !== null && search !== "")
                queryString += `&SearchText=${search}`;

            if (queryString !== "")
                queryString = queryString.replace('&', '?');
            
            let response = await fetch(`/CoursesContoller/GetCourses${queryString}`, {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
            });

            succeeded = response.ok;
            status = response.status;

            let data = await response.text();
            if (data.length > 0) {
                try {
                    let jsonData = JSON.parse(data);
                    data = jsonData;
                    if (data.hasOwnProperty("message"))
                        message = data.message
                }
                catch { }
            }
            
            return new ServiceResponse(succeeded, status, message, data);
        } catch (err) {
            console.log(err);
        }
    }

    async register(courseId) {
        try {
            let succeeded = false;
            let status = "";
            let message = "";

            let response = await fetch("/CoursesContoller/Register", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify({ courseId }),
            });

            succeeded = response.ok;
            status = response.status;

            let data = await response.text();
            if (data.length > 0) {
                try {
                    let jsonData = JSON.parse(data);
                    data = jsonData;
                    if (data.hasOwnProperty("message"))
                        message = data.message
                }
                catch { }
            }

            return new ServiceResponse(succeeded, status, message, data);
        } catch (err) {
            console.log(err);
        }
    }

    async downloadCourse(courseId, fn) {
        try {
            fetch("/CoursesContoller/CourseDownload?courseId=" + courseId, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(response => {
                const filename = response.headers.get('Content-Disposition').split('; ')[1].replace('filename=', '');
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    a.click();
                });
            });
        } catch (err) {
            console.log(err);
        }
    }
}