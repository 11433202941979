import React, { useEffect, useState } from 'react';
import Common from '../common/Common';
import Categories from './Categories';
import Contact from './Contact';
import CoursesService from "../services/CoursesService";
import MonthEvents from "./MonthEvents";
import { useParams, useHistory } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import jQuery from 'jquery';
import './verticalFade.js';

export default function Courses(props) {
    const [courses, setCourses] = useState(null);
    const [months, setMonths] = useState(null);
    const [categoryDetails, setCategoryDetails] = useState(null);

    useEffect(() => {
        (async () => {
            const coursesService = new CoursesService();
            let response = await coursesService.getCourses(props.type, props.cat, props.search);

            if (!response.succeeded) {
                Common.displayError(response.message, "");
                return;
            }

            setCourses(response.data.courses);
            setCategoryDetails(response.data.categoryDetails);

            const months = [];
            for (var i = 0; i < response.data.courses.length; i++) {
                if (response.data.courses[i].courseMonth !== "" && months.includes(response.data.courses[i].courseMonth) === false)
                    months.push(response.data.courses[i].courseMonth);
            }

            setMonths(months);
        })();
    }, [props.type, props.cat, props.search]);

    const params = useParams();
    let typeParam = params.type ?? "הכל";
    let catParam = params.cat ?? "";

    let catTypeParam = typeParam;
    if (catTypeParam === "צור_קשר")
        catTypeParam = "הכל";

    const query = new URLSearchParams(window.location.search);
    const searchParam = query.get('search')

    const readMoreTextToggle = (e) => {
        jQuery("#ReadMoreTextContainer").verticalFade({
            duration: 'slow', complete: function () {
                let readMoreButton = jQuery(this).closest(".categoryPage-header-ttl ").find(".categoryPage-read-more");
                if (readMoreButton.text() == "המשך קריאה")
                    readMoreButton.text('צמצם קריאה');
                else
                    readMoreButton.text('המשך קריאה');
            }
        });
    }

    return (
        <>
            <Carousel showArrows={false} dynamicHeight={true} showStatus={false} showIndicators={false} showThumbs={false} infiniteLoop={true} autoPlay={true} animationHandler="fade">
                <section key="slide1" className={"page-header-img " + (categoryDetails === null || categoryDetails.CategoryHook === null || categoryDetails.CategoryHook === "" ? "" : "d_none")}>
                    <img className="main_img" alt="" src="images/mainimg_d.png" />
                    {/*<img className="logo" alt="לוגו" src="images/logo.png" />*/}
                    {/*<div className="page-header-img-txt">*/}
                    {/*    <h3>*/}
                    {/*        <b>יש גם מסלולים בהתאמה לתפקיד - העשרה מקצועית והתפתחות אישית ממוקדת!</b><br />*/}
                    {/*        בשנת 2023 תוכניות הפוקוס הן למזכירות, כלכלנים, מנהלי פרויקטים ומהנדסים!*/}
                    {/*    </h3>*/}
                    {/*</div>*/}
                    <img className="main_img-m" alt="" src="images/mainimg_m.png" />
                </section>

                {/*<section key="slide2" className={"page-header-img " + (categoryDetails === null || categoryDetails.CategoryHook === null || categoryDetails.CategoryHook === "" ? "" : "d_none")}>*/}
                {/*    <img className="main_img" alt="" src="images/header-img2.png" />*/}
                {/*    <img className="logo fix" alt="לוגו" src="images/logo.png" />*/}
                {/*    <div className="page-header-img-txt page-header-img-txt fix text-mb">*/}
                {/*        <h3>*/}
                {/*            <span className="styled1">אספנו נבחרת של מרצים מצטיינים בנינו תכניות העשרה מרתקות</span>*/}
                {/*            <br/><span className="styled2">ולכם נשאר רק להירשם!</span></h3>*/}
                {/*    </div>*/}
                {/*    <img className="main_img-m2" alt="" src="images/header-img-m2.png" />*/}
                {/*</section>*/}
            </Carousel>

            <div className={categoryDetails && categoryDetails.CategoryHook}>
                <section className={"categoryPage-header-ttl " + (categoryDetails === null || categoryDetails.CategoryHook === null || categoryDetails.CategoryHook === "" ? "d_none" : "")}>
                    <div className="cat-ttl">{categoryDetails && categoryDetails.HeaderTitle}</div>
                    <img className="cat-img" src={categoryDetails && "Uploads/Categories/" + categoryDetails.HeaderImage} alt="" />
                    <span className="cat-txt">
                        {categoryDetails && <span dangerouslySetInnerHTML={{ __html: categoryDetails.HeaderText }} />}
                        {categoryDetails && categoryDetails.ReadMoreText && <><a class="categoryPage-read-more" onClick={readMoreTextToggle}>המשך קריאה</a><div id="ReadMoreTextContainer" className="readMoreTextContainer" dangerouslySetInnerHTML={{ __html: categoryDetails.ReadMoreText }} /></>}
                    </span>
                </section>

                <div className="container">
                    <section className="bread_crumbs">
                        <span>קורסים מומלצים ל:</span>
                        <ul>
                            <Categories type={catTypeParam} />
                        </ul>
                    </section>
                </div>

                <section className="events">
                    <MonthEvents months={months} courses={courses} categoryDetails={categoryDetails} />
                </section>
            </div>
        </>
    );
}
