import React, { useContext } from 'react';
import { GlobalContext } from './state/GlobalState';
import { Route } from 'react-router';
import Login from './components/Login';
import { Layout } from './components/Layout';

import './custom.css'

export default function App() {
    const { isSigned } = useContext(GlobalContext);

    return (
        isSigned?
            <>
                <Route path="/:type?/:cat?"><Layout /></Route>
            </>
            : <Route><Login /></Route>
    );
}
